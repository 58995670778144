import React from 'react';
import axios from 'axios';
import { Link } from 'gatsby';

export default class TrainingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'short',
      data: [],
    }
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(this.props.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  componentDidMount() {
    var type = this.getUrlParameter("type");

    this.setState({
      type
    });

    try {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();
      wow.init();
    } catch (e) {
      console.error(e);
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/training/get?type=${type}`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => { })
  }

  render() {
    const { data, type } = this.state;
    const headerText = type === 'short' ? "Curtas" : "Longas";

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Formações {headerText}</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section className="our_activites">
          <div className="container">
            <div className="row text-center">
              {
                data.length > 0 ?
                  data.map((training, idx) => {
                    var photoUrl = `${process.env.API_URL}${training.Photo}`;
                    var linkUrl = `/training-detail${training.Url.substring(0, training.Url.length - 1)}`;
                    const hasSubscribeLink = !!training.FormUrl;
                    const containerClass = hasSubscribeLink ? "grid-container" : "grid-container-2x";

                    return (
                      <div key={idx} className="col-sm-12">
                        <Link to={linkUrl}>
                          <div className="single_activites card xl">
                            <div className={containerClass}>
                              <div className="grid-item">
                                <img alt="Formação" src={photoUrl} />
                              </div>
                              <div className="grid-item training-card-text">
                                <h4 className="text inline">{training.Name.replace(/&nbsp;/g, '')}</h4>
                                <div className="inline pull-right hidden-xs">
                                  <h4 className="text inline">Data de Início:</h4>
                                  <span className="text">{training.Date || 'A definir'}</span>
                                </div>
                                <h4 className="text">Objetivos</h4>
                                <div className="training-card-text-container list-fade training-list" dangerouslySetInnerHTML={{ __html: training.Objectives }} />
                              </div>
                              {
                                hasSubscribeLink ? <div className="grid-item">
                                  <a className="btn-contact-bg solid xl" target="_blank" href={training.FormUrl}>
                                    <span>Inscreva-se</span>
                                  </a>
                                </div> : null
                              }
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }) :
                  <span>Sem Formações</span>
              }
            </div>
          </div>
        </section>
      </div>
    )
  }
}